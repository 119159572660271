import Vue from 'vue';

const notifyOfDeploymentChange = (index) => {
  const observer = Vue.prototype.discoveryObserver;
  observer.notify('deploymentIndex', index);
};

const fullName = (user) => user && user.name;

export default {

  setActiveUser(state, activeUser) {
    state.activeUser = activeUser;
    state.activeUserNotifier.setValue(activeUser);
  },
  /**
   * This mutation sets the state of the right panel
   * @param state
   * @param value
   */
  setRightPanel(state, value) {
    state.rightPanel = !!value;
  },
  setAppBusy(state, data) {
    state.appBusy = data.busy;
    if (data.message) {
      state.appBusyMessage = data.message;
    } else {
      state.appBusyMessage = 'Loading ...';
    }
  },
  setConsoleBusy(state, data) {
    state.consoleBusy = data.busy;

    if (data.message) {
      state.appBusyMessage = data.message;
    } else {
      state.appBusyMessage = 'Loading ...';
    }
  },
  setAppBusyNoMessage(state, data) {
    state.appBusy = data;
  },
  setConsoleBusyNoMessage(state, data) {
    state.consoleBusy = data;
  },
  setSuccessfulIcon(state, value) {
    state.showSuccessfulIcon = value;
  },
  setUnSuccessfulIcon(state, value) {
    if (value) {
      state.showSuccessfulIcon = false;
    }
    state.showUnSuccessfulIcon = value;
  },
  setShowBusyButtons(state, value) {
    state.showBusyButtons = value;
  },
  addAppBusyButton(state, button) {
    state.appBusyButtons.push(button);
  },
  removeAppBusyButtons(state) {
    state.appBusyButtons = [];
  },
  setDeploymentIndex(state, value) {
    console.log('In Common Setting deployment index', value);
    state.deploymentIndex = value;
    notifyOfDeploymentChange(value);
  },
  // from uploader
  setDeployments(state, data) {
    state.userDeployments = data;
    state.userDeploymentsNotifier.setValue(data);
  },

  /**
   *
   * @param state
   * @param data{Deployment}
   */
  setSelectedDeployment(state, data) {
    if (state.deployment !== data) {
      state.deploymentIndex = null;
    }
    state.deployment = data;
    state.indexColor = data.primaryColor;

    /* if (state.deploymentIndex == null && data.deploymentIndexes?.length > 0) {
      state.deploymentIndex = data.currentIndex;
      notifyOfDeploymentChange(state.deploymentIndex);
      state.deploymentIndexNotifier.setValue(state.deploymentIndex);
    } */
  },
  setSelectedDeploymentIndex(state, data) {
    state.deploymentIndex = data;
    notifyOfDeploymentChange(data);
    state.deploymentIndexNotifier.setValue(data);
  },
  setShowSharePanelState(state, value) {
    state.showSharePanel = value;
  },
  setShowRightPanelState(state, value) {
    state.showRightPanel = value;
  },
  setCurrentRightPanel(state, panel) {
    state.rightPanelName = panel;
  },

  setShowDiscoveryDialog(state, value) {
    state.showDiscoveryDialog = value;
  },

  setCurrentDiscoveryDialog(state, value) {
    state.currentDialogType = value;
  },
  setShareableObject(state, shareable) {
    const name = fullName(state.activeUser);
    const { type } = shareable.shareableObject();

    state.shareMessage = {
      subject: `${name} has shared a ${type} with you`,
    };

    state.shareableObject = shareable;
  },
  setIndexDescription(state, indexDescription) {
    state.indexDescriptionManager.setDescription(indexDescription);
  },
  /**
   *
   * @param state
   * @param notification{DiscoveryNotification}
   */
  addAppNotification(state, notification) {
    state.discoveryNotifier.add(notification);
  },
  addBusyError(state, data) {
    // console.log('push addBusyErrors');
    // console.log(state);
    // console.log(JSON.stringify(data));

    if (!Array.isArray(state.busyErrors)) {
      state.busyErrors = [];
    }
    state.busyErrors.push(data);
  },
  addAssetError(state, data) {
    // console.log('push addBusyErrors');
    // console.log(state);
    // console.log(JSON.stringify(data));
    state.totalAssetErrors += 1;
  },
  resetBusyCursor(state) {
    state.busyErrors = [];
    state.appBusy = false;
    state.consoleBusy = false;
    state.appBusyMessage = '';
    state.appBusyButtons = [];
    state.showBusyButtons = false;
    state.showSuccessfulIcon = false;
    state.showUnSuccessfulIcon = false;
    state.totalAssetErrors = 0;
  },
  resetConsole(state) {
    state.busyErrors = [];
    state.appBusy = false;
    state.consoleBusy = false;
    state.appBusyMessage = '';
    state.appBusyButtons = [];
    state.showBusyButtons = false;
    state.showSuccessfulIcon = false;
    state.showUnSuccessfulIcon = false;
    state.totalAssetErrors = 0;
  },
  openPanel(state, panel) {
    state.rightPanelManager.openPanel(panel);
  },
  closePanel(state, panel) {
    state.rightPanelManager.closePanel(panel);
  },
  emptyPanelStack(state) {
    state.rightPanelManager.emptyStack();
  },

  setCustomizableEntity(state, customizableEntity) {
    state.customizableEntity = customizableEntity;
  },

  clearPageGroup(state) {
    state.pageGroups = [];
  },

  addPageGroup(state, pageGroup) {
    state.pageGroups.push(pageGroup);
  },

  setIndexPermissions(state, permissionsChecker) {
    state.indexPermissions = permissionsChecker;
  },

  updateSearchLog(state, log) {
    state.searchLog = log;
  },

  setDeploymentUsingIndex(state, indexId) {
    state.userDeployments.setUserSelectedDeployment(indexId);
  },

  openMySitesSelectorDialog(state) {
    state.isMySitesSelectorOpen = true;
  },

  closeMySitesSelectorDialog(state) {
    state.isMySitesSelectorOpen = false;
  },

  setMySitesSelectorDialog(state, value) {
    state.isMySitesSelectorOpen = value;
  },

  setUserDefaultIndex(state, value) {
    state.userDefaultIndex = value;
  },

  setIsOpenAiAlertAcknowledged(state, value) {
    state.isOpenAiAlertAcknowledged = value;
  },
};
