import { Observable } from 'rxjs';
import PageGrouping from '@/lib/ui/models/PageGrouping';
import CachingService from '@/lib/util/caching/CachingService';

const buildKey = (indexId) => `nav_items_${indexId}`;

class NavMenuItemsFetcher {
  http = null;

  endpoint = '';

  /**
   *
   * @type {CachingService}
   */
  cache = null;

  /**
   *
   * @param http{AxiosInstance}
   */
  constructor(http) {
    this.http = http;
    this.endpoint = 'v2/acl/portal/navigationItems';
    this.cache = new CachingService();
  }

  async getIndexData(deploymentIndexId) {
    try {
      const { data } = await this.http.get(`${this.endpoint}/${deploymentIndexId}`);
      const pageGroupings = data.data;

      return pageGroupings;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async get(deploymentIndexId) {
    const pageGroupings = await this.getIndexData(deploymentIndexId);
    return PageGrouping.fromList(pageGroupings);
  }

  async getNavItems(deploymentIndexId) {
    const key = buildKey(deploymentIndexId);
    const cachedItems = await this.cache.get(key);
    // console.log('Getting Cached Items from Cache', cachedItems);
    if (cachedItems != null) {
      return cachedItems;
    }

    const url = `${this.endpoint}/${deploymentIndexId}`;
    const { data } = await this.http.get(url);
    // console.log('Getting Cached Items from server', data);

    // console.log('Setting Cached Items');
    await this.cache.set(key, data.data, 5 * 24 * 60 * 1000);
    // console.log(await this.cache.get(key));
    return data.data;
  }

  /**
   *
   * @param deploymentIndexId
   * @returns {Observable<unknown>}
   */
  getObservable(deploymentIndexId) {
    const { http } = this;
    const url = `${this.endpoint}/${deploymentIndexId}`;
    const observable = new Observable(async (observer) => {
      const rawPageGroups = await this.getNavItems(deploymentIndexId);
      if ((rawPageGroups || []).length === 0) {
        observer.complete();
        return;
      }

      rawPageGroups.forEach((pageGroup) => {
        const pg = PageGrouping.from(pageGroup);
        observer.next(pg);
      });
    });

    return observable;
  }
}

export default NavMenuItemsFetcher;
