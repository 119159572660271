class StarfishAssistant {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  endpoint = 'v2/assistant';

  /**
   *
   * @param http {AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  /**
   *
   * @param conversation{AssistantConversation}
   * @returns {Promise<AssistantConversation>}
   */
  async ask(conversation) {
    try {
      conversation.removeOpenAIErrorMessages();
      
      console.log(conversation);
      const { data } = await this.http.post(`${this.endpoint}/ask`, conversation);

      if (conversation.id === '') {
        conversation.id = data.id;
      }

      const { choices } = data;

      (choices || []).forEach(({ message }) => {
        conversation.addAssistantMessage(message.content);
      });

      return conversation;
    } catch (e) {
      console.log(e);
      const { errorMessage } = e.response.data;
      conversation.addErrorMessage(errorMessage);
      return conversation;
    }
  }

  async indexCollections({ type, siteId }) {
    const { data } = await this.http.get(`${this.endpoint}/sites/${siteId}/collections/${type}`);
    return data;
  }
}

export default StarfishAssistant;
