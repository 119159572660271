<template>
  <v-list-item v-ripple="{ class: `brandcolour--text`}"
               :href="path" target="_blank" class="pl-2 navitemlink" v-if="isExternal">
    <v-list-item-icon class="">
      <v-icon size="28" class="material-symbols-outlined">{{ name == 'Information Bots' ? 'smart_toy' : 'mdi-application-settings-outline' }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="globalnavlist font-weight-light d-flex align-center">
        {{ name }}
        <v-spacer/>
        <v-chip label class="text-uppercase font-weight-regular beta-msg" v-if="isBeta">Beta</v-chip>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-list-item v-ripple="{ class: `brandcolour--text`}"
               :to="path" class="pl-2 navitemlink"
                v-else>
    <v-list-item-icon>
      <v-icon size="28" class="material-symbols-outlined">{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="globalnavlist font-weight-light d-flex align-center">
        {{ name }}<v-spacer/>
        <v-chip label class="text-uppercase font-weight-regular home-new-msg" v-if="isNew">New</v-chip>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Page from '@/lib/ui/models/Page';
import { isExternalLink } from '@/lib/ui/navigation/navigationHelper';

export default {
  name: 'NavigationItem',
  data: () => ({
    newLinkNames: [
      'Awards Results',
      'Awards Leaderboard',
      'BI Dashboards',
    ],
    betaLinkNames: [
      'Admin Console',
    ],
  }),
  props: {
    navItem: {
      type: Page,
    },
  },
  computed: {
    path() {
      return this.$urlTransformer.transform(this.navItem?.path);
    },
    name() {
      return this.navItem?.name;
    },
    isExternal() {
      return isExternalLink(this.path);
    },
    icon() {
      return this.navItem?.iconValue;
    },
    isNew() {
      return this.newLinkNames.includes(this.navItem?.name); 
    },
    isBeta() {
      return this.betaLinkNames.includes(this.navItem?.name); 
    },
  },
};
</script>

<style scoped>

</style>
