import Vue from 'vue';
import { map, of } from 'rxjs';
import DeploymentIndex from '@/lib/deployments/models/DeploymentIndex';
import { RightPanels } from '@/lib/ui/panels/RightPanelFactory';
import { DiscoveryNotification } from '@/lib/ui/notifier';
import colors from '@/lib/util/dataSimulator/colors';
// will remove this reference very soon - steve 12/10/20 *****
// will use Vuex-ORM plugin-axios to fetch data and store in vuex
// import httpClient from '@/store/uploader/services';
import SearchLogInput from '@/lib/search/discovery/models/SearchLogInput';
import { systemAlertService, SystemAlertTypes } from '@/lib/systemAlerts/SystemAlertsService';
import { Collection, Group } from '@/lib/business';
import { ApiFactory } from '../../api/api-factory';
import discoveryPortalAppBarVue from '../../components/discoveryPortalAppBar.vue';

const pageActionsService = ApiFactory.getPageActionsService();

/**
 *
 * @type {*|DeploymentsClient}
 */
const deploymentsApi = ApiFactory.getDeploymentsClient();
const deploymentIndexApi = ApiFactory.getDeploymentIndexClient();
const customizationsClient = ApiFactory.getCustomizationsClient();
const navMenuFetcher = ApiFactory.getNavMenuFetcher();

const updateTheme = (css) => {
  const styleSheet = document.getElementById('starfish-theme');
  styleSheet.innerHTML = css;
};

export default {

  setActiveUser({ commit }, activeUser) {
    commit('setActiveUser', activeUser);
  },

  async setDeploymentIndex({ commit, dispatch }, selectedIndex) {
    // await dispatch('common/setDeploymentIndex', selectedIndex);
    await dispatch('setDefaultIndex', selectedIndex);
    // dispatch('setHomeRoute', selectedIndex);
  },

  async setSelectedDeploymentIndex({ commit, dispatch }, selectedIndex) {
    await commit('setSelectedDeploymentIndex', selectedIndex);
  },

  async setUserUserSelectedIndex({ dispatch, commit }, selectedIndex) {
    await dispatch('getCustomizations', selectedIndex);
    await commit('setDeploymentUsingIndex', selectedIndex.deploymentIndexId);
    await dispatch('setSelectedDeploymentIndex', selectedIndex);
    await dispatch('checkIndexPermissions', selectedIndex);
    await dispatch('getPages', selectedIndex);
  },

  showBusyCursor({ commit }, message) {
    commit('setAppBusy', { busy: true, message });
  },

  hideBusyCursor({ commit }) {
    commit('setAppBusy', { busy: false, message: null });
  },
  setShowRightPanelState({ commit }, value) {
    commit('setShowRightPanelState', value);
  },
  setRightPanel({ commit, state }, value) {
    // rightPanelManager needs to be rethought
    // should not have complete object with
    // methods in state
    if (!value && state.rightPanelManager) {
      state.rightPanelManager.close();
    }
    commit('setCurrentRightPanel', value);
  },
  openPanel({ dispatch, commit }, panel) {
    dispatch('emptyPanelStack');
    dispatch('setShowRightPanelState', true);
    dispatch('setRightPanel', panel);
    commit('openPanel', panel);
  },
  closePanel({ dispatch, commit }, panel) {
    dispatch('setShowRightPanelState', false);
    dispatch('setRightPanel', null);
    commit('closePanel', panel);
  },
  emptyPanelStack({ commit }) {
    commit('emptyPanelStack');
  },
  async openNotification({ dispatch }) {
    dispatch('common/openPanel', RightPanels.NOTIFICATION_PANEL, { root: true });
  },
  async openAskBot({ dispatch, commit }) {
    await dispatch('setIsOpenAiAlertAck');
    dispatch('common/openPanel', RightPanels.ASK_BOT, { root: true });
  },
  async openAIWriterCaseStudy({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_WRITING_ASSISTANT_CASE_STUDY, { root: true });
  },
  async openAIWriterCaseStudyTry({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_WRITING_ASSISTANT_CASE_STUDY_TRY, { root: true });
  },
  async openAIWriterResponse({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_WRITING_ASSISTANT_RESPONSE, { root: true });
  },
  async openAIWriterTools({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_WRITING_ASSISTANT_TOOLS, { root: true });
  },
  async openAIContentSummary({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_CONTENT_SUMMARY, { root: true });
  },
  async openAIThemeExtractor({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_THEME_EXTRATOR, { root: true });
  },
  async openAIToneExtractor({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_TONE_EXTRATOR, { root: true });
  },
  async openAISlidePresentation({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_SLIDE_PRESENTATION, { root: true });
  },
  async openAINewsletter({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_NEWSLETTER, { root: true });
  },
  async openAISocialPost({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_SOCIAL_POST, { root: true });
  },
  async openAIImageAnalyzer({ dispatch }) {
    dispatch('common/openPanel', RightPanels.AI_IMAGE_ANALYZER, { root: true });
  },
  async setIsOpenAiAlertAck({ commit }) {
    const isAlertAck = await systemAlertService.getIsSystemAlertAcknowledged(SystemAlertTypes.OPEN_AI);
    await commit('setIsOpenAiAlertAcknowledged', isAlertAck);
  },
  openDialog({ commit }, dialogType) {
    commit('setShowDiscoveryDialog', true);
    commit('setCurrentDiscoveryDialog', dialogType);
  },
  closeDialog({ commit }) {
    commit('setShowDiscoveryDialog', false);
    commit('setCurrentDiscoveryDialog', null);
  },
  setDialogState({ commit }, value) {
    commit('setShowDiscoveryDialog', value);

    if (!value) {
      commit('setCurrentDiscoveryDialog', null);
    }
  },
  openSharePanel({ commit, dispatch }, sharableObject) {
    commit('setShareableObject', sharableObject);
    if (sharableObject instanceof Collection || sharableObject instanceof Group) {
      dispatch('openPanel', RightPanels.COLLECTION_GROUP_SHARE);
    } else {
      dispatch('openPanel', RightPanels.SHARE);
    }
  },
  executeWhenDeploymentIndexIsAvailable({ state }, callback) {
    state.deploymentIndexNotifier.execute(callback);
  },

  executeWhenUserDeploymentsAreAvailable({ state }, callback) {
    return state.userDeploymentsNotifier.execute(callback);
  },

  addAppNotification({ commit }, notification) {
    commit('addAppNotification', notification);
  },
  addAppNotifications({ commit }, notifications) {
    notifications.forEach(async (notification) => {
      await commit('addAppNotification', notification);
    });
  },
  async getUserDeployments({ commit, dispatch }) {
    const data = await deploymentsApi.deployments();
    dispatch('getUserDefaultIndex', data);
    commit('setDeployments', data);

    return data;
  },
  async setDefaultIndex({ commit, dispatch }, index) {
    await deploymentsApi.setDefaultIndex(index);
    const notification = new DiscoveryNotification(
      'Datasource Changed',
      `Your default datasource is now set to '${index.name}'.`,
      null,
    );
    // sdispatch('common/getCustomizations', index, { root: true });
    commit('addAppNotification', notification);
  },
  /**
   *
   * @param _
   * @param currentIndex{DeploymentIndex}
   * @returns {Promise<void>}
   */
  async getCustomizations({ dispatch }, currentIndex) {
    await customizationsClient.getCustomizations(currentIndex);
    await deploymentIndexApi.getTheme(currentIndex);
    if (currentIndex instanceof DeploymentIndex) {
      dispatch('setTheme', currentIndex);
    }
  },
  async saveCustomizations({ dispatch, commit }, entity) {
    commit('setShowBusyButtons', true);
    await customizationsClient.saveCustomizations(entity);
    const notifications = new DiscoveryNotification(
      'Customizations Saved',
      `Customizations for ${entity.name} has been saved`,
      entity,
    );

    dispatch('addAppNotification', notifications);
    dispatch('common/getCustomizations', entity, { root: true });
    // dispatch('setTheme', entity);
    commit('setShowBusyButtons', false);
  },

  async saveDeploymentIndex({ commit }, index) {
    await deploymentIndexApi.saveIndex(index);
    const notification = new DiscoveryNotification(
      'Datasource Theme Changed',
      'You have updated this datasource',
      null,
    );

    commit('addAppNotification', notification);
  },

  async duplicateIndex({ commit }, data) {
    const message = await deploymentsApi.duplicateDeploymentIndex(data).catch((error) => {
      const notification = new DiscoveryNotification(
        error.toString(),
        '',
        null,
      );
      commit('addAppNotification', notification);
    });

    // const message = await deploymentsApi.duplicateDeploymentIndex(data);

    const notification = new DiscoveryNotification(
      message,
      '',
      null,
    );

    commit('addAppNotification', notification);
  },

  async getSettings(deploymentIndexId) {
    await deploymentIndexApi.saveIndex(deploymentIndexId);
  },

  async generateAssetMetadataSets({ commit }, data) {
    const message = await deploymentsApi.generateAssetMetadataSets(data).catch((error) => {
      const notification = new DiscoveryNotification(
        error.toString(),
        '',
        null,
      );
      commit('addAppNotification', notification);
    });

    // const message = await deploymentsApi.duplicateDeploymentIndex(data);

    const notification = new DiscoveryNotification(
      message,
      '',
      null,
    );

    commit('addAppNotification', notification);
  },

  setSuccessIcon({ commit, state, getters }, otherErrorExists) {
    const hasError = getters.hasBusyError;
    console.log(hasError);
    if (otherErrorExists || hasError) {
      commit('common/setUnSuccessfulIcon', true, { root: true });
    } else {
      commit('common/setSuccessfulIcon', true, { root: true });
    }
    return 'ooooiiiiii';
  },

  resetSuccessIcon({ commit }) {
    commit('common/setUnSuccessfulIcon', false, { root: true });
    commit('common/setSuccessfulIcon', false, { root: true });
  },

  customize({ commit }, customizableEntity) {
    commit('setCustomizableEntity', customizableEntity);
  },

  async getPages({ commit }, currentIndex) {
    await commit('clearPageGroup');
    const observable = navMenuFetcher.getObservable(currentIndex.deploymentIndexId);
    const subscription = observable.subscribe({
      next: (pageGroup) => commit('addPageGroup', pageGroup),
      error: console.log,
      complete: () => subscription.unsubscribe(),
    });
  },

  checkIndexPermissions({ commit, state }, currentIndex) {
    const observable = deploymentIndexApi
      .checkPermissionsObserver(currentIndex, state.indexPermissionCodes);
    const subscription = observable.subscribe({
      next: (permissions) => commit('setIndexPermissions', permissions),
      error: console.log,
      complete: () => subscription.unsubscribe(),
    });
  },

  /**
   *
   * @param _
   * @param index{DeploymentIndex}
   */
  setHomeRoute(_, index) {
    Vue.prototype.$starfishRouter.setAlternateRoute('default-home', index.template);
  },

  setTheme(_, entity) {
    const css = entity.customizationSettings.css('cssThemeVariables');
    updateTheme(css);
  },

  async checkForDeploymentIndex({ dispatch }, callback) {
    const getDeployment = (index) => {
      callback(index);
    };

    const deps = await dispatch('executeWhenDeploymentIndexIsAvailable', getDeployment);
  },

  async checkForDeployments({ dispatch }, callback) {
    const getDeployment = (index) => {
      callback(index);
    };

    await dispatch('executeWhenUserDeploymentsAreAvailable', getDeployment);
  },

  getPageActions(_, {
    page,
    appObject,
    appObjectType,
  }) {
    if (!appObject?.pageActions.needsData()) {
      return of(appObject.pageActions);
    }

    const { pageActions } = appObject;
    pageActions.isLoading = true;
    pageActions.clear();

    const observable = pageActionsService.get(page, appObjectType, appObject.id);
    return observable
      .pipe(map((actions) => appObject.pageActions.setActions(actions)));
  },

  updateSearchLog({ commit }, log) {
    commit('updateSearchLog', log);
  },

  resetSearchLog({ commit }) {
    commit('updateSearchLog', new SearchLogInput());
  },

  getUserDefaultIndex({ commit }, data) {
    const deployment = data.deployments.find((d) => d.isCurrent);
    const index = deployment.deploymentIndexes.find((i) => i.isCurrent);
    commit('setUserDefaultIndex', index);
  },

  async getSasToken(_, { deploymentIndexId, containerName, blobName }) {
    const sasToken = await deploymentIndexApi.getContainerSasToken(deploymentIndexId, containerName, blobName);
    return sasToken;
  },

  async setIsMaintenanceAlertAcknowledged() {
    await systemAlertService.setSystemAlertAcknowledged(SystemAlertTypes.MAINTENANCE);
  },

  async setIsOpenAiMessageAcknowledged({ commit }) {
    await commit('setIsOpenAiAlertAcknowledged', true);
    await systemAlertService.setSystemAlertAcknowledged(SystemAlertTypes.OPEN_AI);
  },

  async getStorageInfo(_, { deploymentIndexId, blobName }) {
    const StorageAccountInfo = await deploymentIndexApi.getStorageAccountInfo(deploymentIndexId, blobName);
    return StorageAccountInfo;
  },
};
