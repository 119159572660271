import Asset from '@/lib/business/models/Asset';
import DocumentHighlights from '@/lib/search/discovery/classes/DocumentHighlights';
import { shareableLocation } from '@/lib/util/shareables';
import PageActions from '@/lib/ui/models/PageActions';

import { createIterableFromRawList } from '../../../util';
import DiscoveryDocument from './document';

class Content {
  #insightList = null;

  idField = 'doc_id';

  bindingField = 'doc_id';

  /**
   *
   * @type {InsightContent}
   */
  insight = null;

  /**
   *
   * @type {Assset}
   */
  asset = new Asset();

  assetId = null;

  documentHighlights = null;

  pageActions = new PageActions();

  constructor(content, token, idField, bindingField) {
    this.content = content;
    this.bindingField = bindingField;
    this.document = new DiscoveryDocument(
      content && content.document,
      idField,
      bindingField,
    );
    this.token = token;
    this.idField = idField || 'doc_id';
    this.asset = null;

    const { captions, highlights } = content;
    this.documentHighlights = new DocumentHighlights(captions, highlights, this.document.text);
  }

  get contentTypes() {
    return this.document.contentTypes || [];
  }

  get contentTypeGroups() {
    return this.document?.contentTypeGroups ?? [];
  }

  get documentType() {
    return this.document?.documentType;
  }

  get title() {
    return this.document?.title || this.asset?.assetTitle;
  }

  get filename() {
    return this.document.filename;
  }

  get originalFilename() {
    return this.asset.originalFilename || this.filename;
  }

  get fileType() {
    return this.document.fileType;
  }

  get text() {
    return this.document.text || '';
  }

  get score() {
    return this.content && this.content.score;
  }

  get URL() {
    return this.document && this.document.path(this.token);
  }

  get previewUrl() {
    return this.document && this.document.previewUrl(this.token);
  }

  get id() {
    return this.document.id;
  }

  get path() {
    return this.decodedPath;
  }

  get decodedPath() {
    return this.document?.decodedPath;
  }

  get docId() {
    return this.document?.docId;
  }

  get hasIndexData() {
    return this.document && this.document.hasIndexData;
  }

  get created() {
    return this.document?.created;
  }

  get publishedDate() {
    return this.document?.publishedDate;
  }

  get synopsis() {
    /* Precedence:
    * 1. If search index returns highlighted part of the text, we show that
    * 2. If 1 not available, check if the asset associated with indexed document has a
    *    'description', we show that
    * 3. If none of these are available, we use the document content
    * */
    return this.documentHighlights?.highlight
      || this.document?.synopsis
      || this.document.extractContent();
  }

  setAsset(asset) {
    this.asset = asset;
    this.assetId = asset?.assetId;
    if (asset != null && asset.assetId > 0) {
      this.document.setAsset(asset);
    }
  }

  /**
   * This return raw location that could be resolved to a url
   */
  get rawLocation() {
    return { name: 'content-page', params: { id: this.id } };
  }

  shareableObject(preview = true) {
    return {
      name: this.filename,
      type: 'Document',
      location: shareableLocation(this.rawLocation)(preview),
    };
  }
  
  addToPinnedCollections(collectionIds = []) {
    this.asset.addToPinnedCollections(collectionIds);
  }

  removeFromPinnedCollections(collectionId) {
    this.asset.removeFromPinnedCollections(collectionId);
  }

  isPinnedToCollection(collectionId) {
    if (this.asset == null) {
      return false;
    }

    return this.asset.isPinnedToCollection(collectionId);
  }
}

export default Content;

const createContentWithToken = (token, idField, bindingField) => (content) => new Content(
  content, token, idField, bindingField,
);

/**
 * Converts list of raw content objects to list instances of Content
 * @type {function(...[*]=)}
 */
export const contentListFactory = (token, idField, bindingField) => createIterableFromRawList(
  createContentWithToken(token, idField, bindingField),
);
