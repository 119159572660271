import page from '@/lib/ui/models/Page';
import { DiscoverySearchResult, FacetValue } from '../../lib/search/discovery';

let setFeatured = null;

export default {
  /**
   *
   * @param state
   * @param searchResult {DiscoverySearchResult}
   */
  setSearchResult(state, searchResult) {
    state.discoverSearchResult = searchResult;

    if (searchResult && searchResult.facetList) {
      state.facetList = searchResult.facetList;
    }
  },

  resetContentFilters(state) {
    state.discoverSearchResult = new DiscoverySearchResult();
  },

  /**
   *
   * @param state
   * @param pageNum {number}
   */
  setPage(state, pageNum) {
    state.currentPage = pageNum;
  },

  setOrderBy(state, orderBy) {
    state.orderBy = orderBy;
  },

  setDocumentsPerPage(state, rowsPerPage) {
    state.rowsPerPage = rowsPerPage;
  },

  /**
   *
   * @param state
   * @param suggestions {[string]}
   */
  setSuggestions(state, suggestions) {
    state.searchSuggestions = suggestions;
  },

  /**
   *
   * @param state
   * @param result {DiscoverySearchResult}
   */
  setCiSearchResults(state, result) {
    state.ciSearchResult = result;
  },

  /**
   *
   * @param state
   * @param document {InsightContent}
   */
  setCurrentDocument(state, document) {
    state.currentDocument = document;
  },

  setSearchString(state, searchString) {
    state.searchString = searchString;
    state.searchParams.q = searchString;
  },

  setContentDocument(state, document) {
    state.contentDocument = document;
  },

  /**
   *
   * @param state
   * @param insight {DocumentInsight}
   */
  setDocumentInsight(state, insight) {
    state.documentInsight = insight;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  /**
   *
   * @param state
   * @param questions {[DiscoveryQuestion]}
   */
  setQuestions(state, questions) {
    state.questions = questions;
  },

  setResponseGeneratorPanelState(state, value) {
    state.openResponseGeneratorPanel = value;
  },

  setDashboardMetrics(state, metrics) {
    state.dashboardMetrics = metrics;
  },

  setGroups(state, groups) {
    state.groups = groups;
  },

  setGroup(state, group) {
    state.selectedGroup = group;

    // if this group exists in the list, update it
    if (state.groups != null) {
      state.groups = state.groups.map((existing) => {
        if (existing.id === group.id) {
          return group;
        }

        return existing;
      });
    }
  },

  setCollections(state, data) {
    state.collections = data;
  },

  setCollection(state, data) {
    state.featuredCollections.checkIfIsFeatured([data]);
    state.selectedCollection = data;
    state.collectionAssetSearchInput.collectionId = data.id;

    if (state.collections != null) {
      state.collections = state.collections.map((existing) => {
        if (existing.id === data.id) {
          return data;
        }

        return existing;
      });
    }
  },

  /**
   *
   * @param state
   * @param data {CollectionFormInput}
   */
  setCollectionFormInput(state, data) {
    state.collectionFormInput = data;
  },

  setShowGroupEditor(state, value) {
    state.showGroupEditor = value;
  },

  setEditableType(state, type) {
    state.editableType = type;
  },

  addToGroups(state, group) {
    if (state.groups == null) {
      state.groups = [];
    }

    state.groups.unshift(group);
  },
  removeCollection(state, collectionId) {
    state.collections = state.collections.filter((rec) => rec.collectionId !== collectionId);
  },
  removeGroup(state, groupId) {
    state.groups = state.groups.filter((rec) => rec.groupId !== groupId);
  },
  /**
   * This flag is used to open/close AddCollectionsToGroup dialog
   * @param state
   * @param value {Boolean}
   */
  setShowAddToCollectionDialog(state, value) {
    state.showAddToCollectionDialog = value;
  },

  /**
   * Sets collections that could be added to a group
   * @param state
   * @param collections{Collection[]}
   */
  setAvailableCollections(state, collections) {
    state.availableCollections = collections;
  },

  /**
   *
   * @param state
   * @param groups{Group[]}
   */
  setAvailableGroups(state, groups) {
    state.availableGroups = groups;
  },

  setContentInsightModalData(state, { insight, phrase }) {
    state.currentInsight = insight;
    state.currentInsightPhrase = phrase;
  },

  setCollectionGroupEditSectionPanel(state, value) {
    state.collectionGroupEditSection.setOpenPanel(value);
  },

  /**
   *
   * @param state
   * @param editableDocument {EditableAsset}
   */
  setEditableAsset(state, editableDocument) {
    state.editableDocument = editableDocument;
  },

  setDashboardInsights(state, insights) {
    state.dashboardInsights = insights;
  },

  setFeaturedCollections(state, collections) {
    state.featuredCollections = collections;
  },

  markCollectionsAsFeatured(state) {
    if (setFeatured == null) {
      setFeatured = setTimeout(() => {
        state.featuredCollections.checkIfIsFeatured(state.collections);
        setFeatured = null;
      });
    }
  },

  removeFromFeaturedCollection(state, collection) {
    state.featuredCollections.remove(collection);
  },

  addToFeaturedCollection(state, collection) {
    state.featuredCollections.addAndSort(collection);
  },

  setFeaturedGroups(state, groups) {
    state.featuredGroups = groups;
  },

  markGroupsAsFeatured(state) {
    if (setFeatured == null) {
      setFeatured = setTimeout(() => {
        state.featuredGroups.checkIfIsFeatured(state.groups);
        setFeatured = null;
      });
    }
  },

  removeFromFeaturedGroup(state, group) {
    state.featuredGroups.remove(group);
  },

  addToFeaturedGroup(state, group) {
    state.featuredGroups.addAndSort(group);
  },

  setGroupCounts(state, countListResult) {
    state.groupCounts = countListResult;
  },

  setCollectionCounts(state, countListResult) {
    state.collectionCounts = countListResult;
  },

  setSelectedEditableEntity(state, entity) {
    state.selectedEditableEntity = entity;
  },

  addToCollections(state, collection) {
    if (state.collections == null) {
      state.collections = [];
    }

    state.collections.unshift(collection);
  },

  setSearchError(state, error) {
    state.searchError = error;
  },

  setIsSemanticQuery(state, value) {
    state.searchParams.isSemanticSearch = value;
  },

  setCurrentRendererType(state, value) {
    state.currentSearchRendererType = (value || 'card');
  },

  setInitialMetrics(state, metrics) {
    state.baseMetrics = metrics;
  },
  async bindDefaultFiltersToCurrentFilters(state) {
    const allFacets = state.baseMetrics?.facetList;
    if (allFacets != null) {
      const currentFilter = [{
        key: 'published_date',
        value: ['-3 months'],
      }];
      const publisdedDateFacet = allFacets.facets.filter((f) => f.key === 'published_date')[0];
      const fv = new FacetValue('-3 months', 0, true);
      publisdedDateFacet.values = [fv];
      publisdedDateFacet.selectedValues = ['-3 months'];
      publisdedDateFacet.selected = true;

      if (!allFacets.addFacetToList(publisdedDateFacet, true)) {
        allFacets.updatedSelectedFacets();
      }
      allFacets.selectedFacets = currentFilter;
      state.baseMetrics.facetList = allFacets;
    }
  },
  setApplyPublishDateFilter(state, value) {
    state.applyPublishedDateFilterOnPageLoad = value;
  },
  setSearchParams(state, params) {
    state.searchParams = params;
  },

  setFilePreview(state, preview) {
    state.currentFilePreview = preview;
  },
  setFilePreviewLoading(state, isLoading) {
    state.loadingFilePreview = isLoading;

    if (isLoading) {
      state.currentFilePreview = null;
    }
  },

  setEditPreviewInput(state, input) {
    state.editPreviewInput = input;
  },

  removeDocumentFromSearchResult(state, asset) {
    if (state.discoverSearchResult?.documentResult?.contents) {
      const searchResult = state.discoverSearchResult;
      const contents = searchResult.documentResult.contents || [];
      searchResult.documentResult.contents = contents.filter(
        (content) => content.id !== asset.id,
      );
      state.discoverSearchResult = searchResult;
    }
  },

  setRelatedCollections(state, result) {
    state.relatedCollectionsResult = result;
  },

  setDocumentInteraction(state, insight) {
    state.documentInteraction = insight;
  },

  setFacetSortOrder(state, order) {
    state.facetSortOrder = order;
  },

  setSelectedCollectionAssets(state, result) {
    state.selectedCollectionAssets = result;
    state.selectedCollection.collectionAssetsCount = result.totalRecords;
  },
  setSelectedPinnedCollectionAssets(state, result) {
    state.selectedPinnedCollectionAssets = result;
  },

  resetCollectionAssets(state, result) {
    if (state.selectedCollectionAssets != null) {
      state.selectedCollectionAssets = null;
    }
  },
  resetPinnedCollectionAssets(state, result) {
    if (state.selectedPinnedCollectionAssets != null) {
      state.selectedPinnedCollectionAssets = null;
    }
  },

  updateFacetListSort(state, sortOrder) {
    state.discoverSearchResult.facetList.sortBy = (sortOrder || state.facetSortOrder);
  },

  resetSearchParams(state) {
    state.searchParams.reset();
  },

  setDashboardInsightType(state, type) {
    state.dashboardInsightType = type;
  },

  setAssetAssignments(state, assignments) {
    state.assetAssignments = assignments;
  },

  setAvailableUsersForAssignment(state, users) {
    state.availableUsersForAssignment = users;
  },

  setLoadingMetrics(state, value) {
    state.loadingMetrics = value;
  },

  setVideoIndexerWidgetSettings(state, value) {
    console.log('Got Settings', value);
    state.videoIndexerWidgetSettings = value;
  },

  setVideoInsightsLoading(state, value) {
    state.videoInsightsLoading = value;
  },

  setCollectionSearchResult(state, result) {
    state.collectionAssetSearchResult = result;
  },

  updateCollectionAssetSearchItemsPerPage(state, itemsPerPage) {
    state.collectionAssetSearchInput.itemsPerPage = itemsPerPage;
  },

  updateCollectionAssetSearchOrderBy(state, orderBy) {
    state.collectionAssetSearchInput.orderBy = orderBy;
  },

  updateCollectionAssetSearchPageNumber(state, pageNum) {
    state.collectionAssetSearchInput.pageNum = pageNum;
  },

  updateCollectionAssetSearchQuery(state, query) {
    state.collectionAssetSearchInput.searchParameters.q = query;
  },

  resetCollectionAssetsSearch(state) {
    state.collectionAssetSearchInput.searchParameters.reset();
  },

  setAssetSets(state, value) {
    state.assetSetsResult = value;
  },

  setAssetCampaigns(state, value) {
    state.assetCampaignsResult = value;
  },

  setAssetAwards(state, value) {
    state.assetAwardsResult = value;
  },

  setAssetExternalUrl(state, value) {
    state.assetExternalUrlResult = value;
  },

  setAssetExternalUrlLabel(state, value) {
    state.assetExternalUrlLabelResult = value;
  },

  setAssetExternalUrlAction(state, value) {
    state.assetExternalUrlActionResult = value;
  },

  setAssetExternalUrlDescription(state, value) {
    state.assetExternalUrlDescriptionResult = value;
  },
  
  setCustomizationsPanelTitle(state, title) {
    state.customizationPanelManager.setPanelTitle(title);
  },

  setCustomizationsPanelType(state, panelType) {
    state.customizationPanelManager.setPanelType(panelType);
  },

  setTotalPages(state, count) {
    const { rowsPerPage } = state;
    state.totalPages = Math.ceil(count / rowsPerPage);
  },

  setAssetRemovedFromColleciton(state, value) {
    state.assetRemovedFromCollection = value;
  },

  setFacetChipsForSemanticSearch(state, isSemantic) {
    state.showFacetChips = isSemantic;
  },

  setAppObjectInteractionInsights(state, insights) {
    state.appObjectInteractionInsights = insights;
  },
  setAwardsData(state, value) {
    state.awardsData = value;
  },
};
