import SearchLogInput from '@/lib/search/discovery/models/SearchLogInput';

export const isQuoted = (q) => {
  console.log('Checking if q is quoted', q);
  const regexp = /^(".*?")|('.*?')$/g;
  return q && regexp.test(q);
};

const quoteText = (text) => `"${text}"`;

const searchFields = [
  'doc_id',
  'id',
  'content',
  'title',
  'created',
  'created_by',
  'file_name',
  'metadata_spo_site_library_item_id',

  'metadata_storage_content_type',
  'metadata_spo_item_content_type',

  'metadata_storage_size',
  'metadata_spo_item_size',

  'metadata_storage_content_size',
  'metadata_storage_last_modified',
  'metadata_storage_content_md5',

  'metadata_storage_name',
  'metadata_spo_item_name',

  'metadata_storage_path',
  'metadata_spo_item_path',
  'metadata_spo_item_weburi',

  'metadata_storage_file_extension',
  'metadata_content_type',
  'uploader/content_types',
  'content_types',
  'content_type_groups',
  'published_date',
  'updated',
  'updated_by',
  'file_type_category',
  'key_contacts',
];

export const toFullTextQuery = (q) => {
  const text = (q || q.trim()).replaceAll(/\s+/g, '+');

  if (isQuoted(text)) {
    return text;
  }

  return quoteText(text);
};

const SearchParameters = function SearchParameters(query, isSemanticSearch) {
  this.q = query || '';
  this.queryType = 'full';
  this.documentsPerPage = 25;
  this.page = 1;
  this.indexId = null;
  this.orderBy = null;
  this.filters = [];
  this.isfullTextQuery = false;
  this.isSemanticSearch = isSemanticSearch;
  this.facetValuesCount = 12000;
  this.select = [];

  this.source = '';
  this.pageName = '';
};

SearchParameters.prototype.setQueryType = function setQueryType(queryType) {
  this.queryType = queryType;
  return this;
};

SearchParameters.prototype.toQuery = function toQuery() {
  return {
    q: this.searchText || '*',
    //    documentsPerPage: this.documentsPerPage,
    documentsPerPage: this.calculatedDocsPerPage,
    page: this.page,
    indexId: this.indexId,
    orderBy: this.calculatedOrderBy,
    filters: this.filters || [],
    queryType: this.queryType,
    facetValuesCount: this.facetValuesCount,
    select: searchFields,
    source: this.source,
    pageName: this.pageName,
  };
};

SearchParameters.prototype.preview = function preview(query) {
  if (this.isfullTextQuery) {
    return toFullTextQuery(query);
  }

  return query || '*';
};

SearchParameters.prototype.reset = function reset() {
  this.q = '';
  this.isSemanticSearch = false;
  this.isfullTextQuery = false;
  this.page = 1;
};

SearchParameters.prototype.setSelectFields = function selectFields(fields) {
  this.select = fields;
  return this;
};

Object.defineProperty(SearchParameters.prototype, 'isSemanticSearch', {
  get() {
    return this.queryType && this.queryType === 'semantic';
  },
  set(value) {
    if (value) {
      this.queryType = 'semantic';
    } else {
      this.queryType = 'full';
    }
  },
});

Object.defineProperty(SearchParameters.prototype, 'searchText', {
  get() {
    if (this.isfullTextQuery) {
      return toFullTextQuery(this.q);
    }

    return this.q;
  },
});

Object.defineProperty(SearchParameters.prototype, 'calculatedOrderBy', {
  get() {
    if (this.isSemanticSearch) {
      return null;
    }

    return this.orderBy;
  },
});
Object.defineProperty(SearchParameters.prototype, 'calculatedDocsPerPage', {
  get() {
    return this.documentsPerPage;
  },
});

export default SearchParameters;

export const searchParamFactory = (callback) => callback(new SearchParameters());
